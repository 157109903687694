import React, { useEffect, useState } from 'react'
import Layout from '../../../layout/Layout'
import { Toolbar } from 'primereact/toolbar'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from '../../../provider/AppProvider'
import { adicionar, atualizar, consultarPorId } from './CategoriaService'

const CategoriaForm = () => {
    const navigate = useNavigate()
    const { apiErrorHandle, showToast } = useApp()
    let { categoriaId } = useParams()
    const [nome, setNome] = useState('')

    useEffect(() => {
        if (categoriaId) {
            console.log('Init categoriaForm')
            consultarPorId(categoriaId)
                .then((data) => {
                    console.log('data', data)
                    setNome(data.nome)
                })
                .catch((error) =>
                    apiErrorHandle({
                        error,
                        mensagem: 'Falha ao consultar a categoria',
                    }),
                )
        }
    }, [apiErrorHandle, categoriaId, setNome])

    const handleSalvar = () => {
        if (categoriaId) {
            handleAtualizar()
            return
        }
        handleAdicionar()
    }

    const handleAdicionar = () => {
        adicionar({ nome })
            .then(() => {
                navigate('/categoria')
                showToast({
                    detail: 'Registro salvo com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao adicionar uma nova categoria',
                }),
            )
    }

    const handleAtualizar = () => {
        atualizar({ id: categoriaId, nome })
            .then(() => {
                navigate('/categoria')
                showToast({
                    detail: 'Registro atualizado com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao adicionar uma nova categoria',
                }),
            )
    }

    const startToolbarTemplate = () => {
        return (
            <div>
                <span className="text-xl">Categoria</span>
            </div>
        )
    }

    return (
        <Layout>
            <div className="grid mt-2 m-0 p-3 xl:p-0">
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0">
                    <Toolbar className="mb-2" start={startToolbarTemplate} />
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0 ">
                    <InputText
                        type="text"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        className="p-inputtext-lg w-full mb-2"
                        placeholder="Informe o nome da categoria"
                    />
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-8 p-0"></div>
                <div className="col-12 xl:col-2 m-0 p-0 flex justify-content-end ">
                    <Button
                        label="Salvar"
                        icon="pi pi-check"
                        className="mr-2"
                        onClick={handleSalvar}
                        size="small"
                        text
                    />
                    <Button
                        label="Cancelar"
                        onClick={() => navigate('/categoria')}
                        severity="danger"
                        icon="pi pi-times"
                        size="small"
                        text
                    />
                </div>
            </div>
        </Layout>
    )
}

export default CategoriaForm
