import { Navigate } from 'react-router-dom'
import { useAuth } from '../provider/AuthProvider'
import ProtectedRoute from './ProtectedRoute'

const AdminRoute = ({ children }) => {
    const { isAdmin } = useAuth()
    if (!isAdmin) {
        return <Navigate to="/app" replace />
    }

    return <ProtectedRoute>{children}</ProtectedRoute>
}

export default AdminRoute
