import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import { AuthProvider } from '../provider/AuthProvider'
import { ToastProvider } from '../provider/ToastProvider'

import Login from '../pages/public/login/Login'
import NotFoundPage from '../pages/public/not-found/NotFoundPage'

import Home from '../pages/private/Home'
import CategoriaList from '../pages/private/categoria/CategoriaList'
import CategoriaForm from '../pages/private/categoria/CategoriaForm'
import { AppProvider } from '../provider/AppProvider'
import ProdutoList from '../pages/private/produto/ProdutoList'
import ProdutoForm from '../pages/private/produto/ProdutoForm'
import TipoFornecedorList from '../pages/private/tipo-fornecedor/TipoFornecedorList'
import TipoFornecedorForm from '../pages/private/tipo-fornecedor/TipoFornecedorForm'
import FornecedorForm from '../pages/private/fornecedor/FornecedorForm'
import FornecedorList from '../pages/private/fornecedor/FornecedorList'
import CompraList from '../pages/private/compra/CompraList'
import CompraForm from '../pages/private/compra/CompraForm'
import CompraItemList from '../pages/private/compra-item/CompraItemList'
import CompraItemForm from '../pages/private/compra-item/CompraItemForm'
import AdminRoute from './AdminRoute'

function AppRoutes() {
    return (
        <BrowserRouter>
            <ToastProvider>
                <AuthProvider>
                    <AppProvider>
                        <Routes>
                            <Route element={<NotFoundPage />} />

                            {/* Public */}
                            <Route exact path="/" element={<Login />} />

                            {/* Protected */}
                            <Route
                                exact
                                path="/app"
                                element={
                                    <ProtectedRoute>
                                        <Home />
                                    </ProtectedRoute>
                                }
                            />

                            <Route path="*" element={<NotFoundPage />} />
                            <Route
                                exact
                                path="/categoria"
                                element={
                                    <AdminRoute>
                                        <CategoriaList />
                                    </AdminRoute>
                                }
                            />
                            <Route
                                path="/categoria/form"
                                element={
                                    <AdminRoute>
                                        <CategoriaForm />
                                    </AdminRoute>
                                }
                            />
                            <Route
                                path="/categoria/form/:categoriaId"
                                element={
                                    <AdminRoute>
                                        <CategoriaForm />
                                    </AdminRoute>
                                }
                            />

                            <Route
                                exact
                                path="/produto"
                                element={
                                    <AdminRoute>
                                        <ProdutoList />
                                    </AdminRoute>
                                }
                            />
                            <Route
                                path="/produto/form"
                                element={
                                    <AdminRoute>
                                        <ProdutoForm />
                                    </AdminRoute>
                                }
                            />
                            <Route
                                path="/produto/form/:produtoId"
                                element={
                                    <AdminRoute>
                                        <ProdutoForm />
                                    </AdminRoute>
                                }
                            />

                            <Route
                                exact
                                path="/tipo-fornecedor"
                                element={
                                    <AdminRoute>
                                        <TipoFornecedorList />
                                    </AdminRoute>
                                }
                            />
                            <Route
                                path="/tipo-fornecedor/form"
                                element={
                                    <AdminRoute>
                                        <TipoFornecedorForm />
                                    </AdminRoute>
                                }
                            />
                            <Route
                                path="/tipo-fornecedor/form/:tipoFornecedorId"
                                element={
                                    <AdminRoute>
                                        <TipoFornecedorForm />
                                    </AdminRoute>
                                }
                            />

                            <Route
                                exact
                                path="/fornecedor"
                                element={
                                    <AdminRoute>
                                        <FornecedorList />
                                    </AdminRoute>
                                }
                            />
                            <Route
                                path="/fornecedor/form"
                                element={
                                    <AdminRoute>
                                        <FornecedorForm />
                                    </AdminRoute>
                                }
                            />
                            <Route
                                path="/fornecedor/form/:fornecedorId"
                                element={
                                    <AdminRoute>
                                        <FornecedorForm />
                                    </AdminRoute>
                                }
                            />

                            <Route
                                exact
                                path="/compra"
                                element={
                                    <ProtectedRoute>
                                        <CompraList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/compra/form"
                                element={
                                    <ProtectedRoute>
                                        <CompraForm />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/compra/form/:compraId"
                                element={
                                    <ProtectedRoute>
                                        <CompraForm />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                exact
                                path="/compra-item/:compraId"
                                element={
                                    <ProtectedRoute>
                                        <CompraItemList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/compra-item/form/:compraId"
                                element={
                                    <ProtectedRoute>
                                        <CompraItemForm />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/compra-item/form/:compraId/:compraItemId"
                                element={
                                    <ProtectedRoute>
                                        <CompraItemForm />
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                    </AppProvider>
                </AuthProvider>
            </ToastProvider>
        </BrowserRouter>
    )
}

export default AppRoutes
