import React, { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { autenticar } from '../service/auth'
import { AppConstantes } from '../constantes/app'

const AuthContext = createContext()

export function AuthProvider({ children }) {
    const [token, setToken] = useState(
        localStorage.getItem(AppConstantes.TOKEN),
    )
    const [usuario, setUsuario] = useState(
        localStorage.getItem(AppConstantes.USUARIO),
    )
    const [usuarioId, setUsuarioId] = useState(
        localStorage.getItem(AppConstantes.ID_USUARIO),
    )
    const [papelUsuario, setPapelUsuario] = useState(
        localStorage.getItem(AppConstantes.PAPEL_USUARIO),
    )
    const [lembrarMeUsuario, setLembrarMeUsuario] = useState(
        localStorage.getItem(AppConstantes.LEMBRAR_ME_USUARIO),
    )

    const navigate = useNavigate()

    const login = async ({ username, password }) => {
        var { token, usuario, usuarioId, papelUsuario } = await autenticar({
            username,
            password,
        })

        localStorage.setItem(AppConstantes.TOKEN, JSON.stringify(token))
        localStorage.setItem(AppConstantes.USUARIO, usuario)
        localStorage.setItem(AppConstantes.ID_USUARIO, usuarioId)
        localStorage.setItem(AppConstantes.PAPEL_USUARIO, papelUsuario)

        setToken(localStorage.getItem(AppConstantes.TOKEN))
        setUsuario(localStorage.getItem(AppConstantes.USUARIO))
        setUsuarioId(localStorage.getItem(AppConstantes.ID_USUARIO))
        setPapelUsuario(localStorage.getItem(AppConstantes.PAPEL_USUARIO))

        console.log(papelUsuario)

        navigate(
            papelUsuario.includes(AppConstantes.NOME_PAPEL_ADMINISTRADOR)
                ? '/app'
                : '/compra',
        )
    }

    const logout = () => {
        localStorage.removeItem(AppConstantes.TOKEN)
        localStorage.removeItem(AppConstantes.USUARIO)
        localStorage.removeItem(AppConstantes.ID_USUARIO)
        localStorage.removeItem(AppConstantes.PAPEL_USUARIO)

        setToken(null)
        setUsuario(null)
        setUsuarioId(null)
        setPapelUsuario([])

        navigate('/')
    }

    const lembrarMe = ({ lembrarMe, username, password }) => {
        if (lembrarMe) {
            localStorage.setItem(
                AppConstantes.LEMBRAR_ME_USUARIO,
                JSON.stringify({
                    username,
                    password,
                }),
            )
            setLembrarMeUsuario(
                localStorage.getItem(AppConstantes.LEMBRAR_ME_USUARIO),
            )
        } else {
            localStorage.removeItem(AppConstantes.LEMBRAR_ME_USUARIO)
            setLembrarMeUsuario(null)
        }
    }

    return (
        <AuthContext.Provider
            value={{
                login,
                logout,
                logged: token !== null,
                usuario,
                usuarioId,
                token: JSON.parse(token),
                isAdmin:
                    AppConstantes.NOME_PAPEL_ADMINISTRADOR === papelUsuario,
                lembrarMe,
                lembrarMeUsuario: JSON.parse(lembrarMeUsuario),
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}
