import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../../layout/Layout'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from '../../../provider/AppProvider'
import { adicionar, atualizar, consultarPorId } from './CompraItemService'
import { Dropdown } from 'primereact/dropdown'
import { listar as listarProdutos } from '../produto/ProdutoService'
import { consultarPorId as consultarCompraPorId } from '../compra/CompraService'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import Loading from '../../../shared/loading/Loading'

const CompraItemForm = () => {
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()
    const { apiErrorHandle, showToast } = useApp()
    let { compraId } = useParams()
    let { compraItemId } = useParams()

    const [produtos, setProdutos] = useState([])
    const [produto, setProduto] = useState(null)
    const [compra, setCompra] = useState({ fornecedor: '' })

    const [quantidade, setQuantidade] = useState(1)
    const [preco, setPreco] = useState(0)

    const handleListarProdutos = useCallback(async () => {
        console.log('Init ComprarList.ListarProdutos')
        await listarProdutos()
            .then((data) => setProdutos(data))
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao obter lista de produtos',
                }),
            )
    }, [apiErrorHandle, setProdutos])

    useEffect(() => {
        const init = async () => {
            await handleListarProdutos()
            console.log('Init CompraItemForm')

            await consultarCompraPorId(compraId)
                .then((data) => {
                    setCompra(data)
                })
                .catch((error) =>
                    apiErrorHandle({
                        error,
                        mensagem: 'Falha ao consultar a compra',
                    }),
                )
            console.log(compraItemId)
            if (compraItemId) {
                console.log('Init Consultar CompraItem')
                await consultarPorId(compraItemId)
                    .then((data) => {
                        setProduto(data.produto)
                        setQuantidade(data.quantidade)
                        var preco = Number(data.preco).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        })
                        setPreco(preco)
                    })
                    .catch((error) =>
                        apiErrorHandle({
                            error,
                            mensagem: 'Falha ao consultar a compraItem',
                        }),
                    )
            }
        }

        init().finally(() => {
            setLoading(false)
        })
    }, [apiErrorHandle, compraId, compraItemId, handleListarProdutos])

    const handleSalvar = () => {
        setLoading(true)
        if (compraItemId) {
            handleAtualizar()
            return
        }
        handleAdicionar()
    }

    const handleAdicionar = () => {
        adicionar({ produto, compra })
            .then(() => {
                navigate(`/compra-item/${compraId}`)
                showToast({
                    detail: 'Registro salvo com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao adicionar uma nova compra-item',
                }),
            )
            .finally(() => {
                setLoading(false)
            })
    }

    const handleAtualizar = () => {
        atualizar({ id: compraItemId, quantidade, preco: getPrecoNumerico() })
            .then(() => {
                navigate(`/compra-item/${compraId}`)
                showToast({
                    detail: 'Registro atualizado com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao atualizar uma nova compra-item',
                }),
            )
            .finally(() => {
                setLoading(false)
            })
    }

    const startToolbarTemplate = () => {
        return (
            <div>
                <span className="text-xl">{compra.fornecedor.nome}</span>
            </div>
        )
    }

    const handlePrecoInputChange = (event) => {
        // Obtém o valor digitado pelo usuário
        let inputValue = event.target.value

        // Remove tudo que não for número ou ponto
        inputValue = inputValue.replace(/\D/g, '')

        // Formata para o formato de moeda BRL
        inputValue = Number(inputValue / 100).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        })

        setPreco(inputValue)
    }

    const getPrecoNumerico = () => {
        return parseFloat(preco.replace(/[^\d,-]/g, '').replace(',', '.'))
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Layout>
                    <div className="grid mt-2 m-0 p-3 xl:p-0">
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="col-12 xl:col-8 m-0 p-0">
                            <Toolbar
                                className="mb-2 min-height66px"
                                start={startToolbarTemplate}
                            />
                        </div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="col-12 xl:col-8 m-0 p-0 ">
                            <Dropdown
                                value={produto}
                                onChange={(e) => setProduto(e.value)}
                                filter
                                options={produtos}
                                disabled={compraItemId}
                                optionLabel="nome"
                                placeholder="Selecione o produto"
                                className="p-inputtext-lg w-full mb-2"
                            />
                        </div>
                        {compraItemId ? (
                            <>
                                <div className="hidden xl:block col-offset-2 p-0"></div>
                                <div className="hidden xl:block col-offset-2 p-0"></div>
                                <div className="col-12 xl:col-8 m-0 p-0 ">
                                    <InputNumber
                                        value={quantidade}
                                        onChange={(e) => setQuantidade(e.value)}
                                        className="p-inputtext-lg w-full mb-2"
                                        min={1}
                                    />
                                </div>
                                <div className="hidden xl:block col-offset-2 p-0"></div>
                                <div className="hidden xl:block col-offset-2 p-0"></div>
                                <div className="col-12 xl:col-8 m-0 p-0 ">
                                    <InputText
                                        className="p-inputtext-lg w-full mb-2"
                                        value={preco}
                                        onChange={handlePrecoInputChange}
                                    />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="hidden xl:block col-offset-8 p-0"></div>
                        <div className="col-12 xl:col-2 m-0 p-0 flex justify-content-end ">
                            <Button
                                label="Salvar"
                                icon="pi pi-check"
                                onClick={handleSalvar}
                                className={
                                    'mr-2 ' + (compra.concluida ? 'hidden' : '')
                                }
                                size="small"
                                text
                            />
                            <Button
                                label="Cancelar"
                                onClick={() =>
                                    navigate(`/compra-item/${compraId}`)
                                }
                                severity="danger"
                                icon="pi pi-times"
                                size="small"
                                text
                            />
                        </div>
                    </div>
                </Layout>
            )}
        </>
    )
}

export default CompraItemForm
