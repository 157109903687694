import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../../layout/Layout'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from '../../../provider/AppProvider'
import { adicionar, atualizar, consultarPorId } from './CompraService'
import { Dropdown } from 'primereact/dropdown'
import { listar as listarFornecedores } from '../fornecedor/FornecedorService'
import { useAuth } from '../../../provider/AuthProvider'
import Loading from '../../../shared/loading/Loading'

const CompraForm = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)

    const { apiErrorHandle, showToast } = useApp()
    const { usuarioId } = useAuth()
    let { compraId } = useParams()

    const [fornecedores, setFornecedores] = useState([])
    const [fornecedor, setFornecedor] = useState('')

    const handleListarFornecedores = useCallback(async () => {
        console.log('Init CompraForm.Fornecedores')
        await listarFornecedores()
            .then((data) => setFornecedores(data))
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao obter lista de fornecedores',
                }),
            )
    }, [apiErrorHandle, setFornecedores])

    useEffect(() => {
        const init = async () => {
            await handleListarFornecedores()
            if (compraId) {
                console.log('Init CompraForm')
                await consultarPorId(compraId)
                    .then((data) => {
                        console.log('data', data)
                        setFornecedor(data.fornecedor)
                    })
                    .catch((error) =>
                        apiErrorHandle({
                            error,
                            mensagem: 'Falha ao consultar a compra',
                        }),
                    )
            }
        }
        init().finally(() => {
            setLoading(false)
        })
    }, [apiErrorHandle, compraId, handleListarFornecedores, setLoading])

    const handleSalvar = () => {
        setLoading(true)
        if (compraId) {
            handleAtualizar()
            return
        }
        handleAdicionar()
    }

    const handleAdicionar = () => {
        adicionar({ fornecedor, usuarioId })
            .then(() => {
                navigate('/compra')
                showToast({
                    detail: 'Registro salvo com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao adicionar um novo compra',
                }),
            )
            .finally(() => {
                setLoading(false)
            })
    }

    const handleAtualizar = () => {
        atualizar({ id: compraId, fornecedor })
            .then(() => {
                navigate('/compra')
                showToast({
                    detail: 'Registro atualizado com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao adicionar uma nova compra',
                }),
            )
            .finally(() => {
                setLoading(false)
            })
    }

    const startToolbarTemplate = () => {
        return (
            <div>
                <span className="text-xl">Compra</span>
            </div>
        )
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Layout>
                    <div className="grid mt-2 m-0 p-3 xl:p-0">
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="col-12 xl:col-8 m-0 p-0">
                            <Toolbar
                                className="mb-2"
                                start={startToolbarTemplate}
                            />
                        </div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="col-12 xl:col-8 m-0 p-0 ">
                            <Dropdown
                                value={fornecedor}
                                onChange={(e) => setFornecedor(e.value)}
                                options={fornecedores}
                                optionLabel="nome"
                                placeholder="Selecione o fornecedor"
                                className="p-inputtext-lg w-full mb-2"
                            />
                        </div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="hidden xl:block col-offset-8 p-0"></div>
                        <div className="col-12 xl:col-2 m-0 p-0 flex justify-content-end ">
                            <Button
                                label="Salvar"
                                icon="pi pi-check"
                                onClick={handleSalvar}
                                className="mr-2"
                                size="small"
                                text
                            />
                            <Button
                                label="Cancelar"
                                onClick={() => navigate('/compra')}
                                severity="danger"
                                icon="pi pi-times"
                                size="small"
                                text
                            />
                        </div>
                    </div>
                </Layout>
            )}
        </>
    )
}

export default CompraForm
