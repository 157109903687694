import axios from 'axios'
import { AppConstantes } from '../constantes/app'

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_CONTEXT}`,
})

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(AppConstantes.TOKEN)
        if (token) {
            config.headers.Authorization = `Bearer ${
                JSON.parse(token).access_token
            }`
        }
        return config
    },
    (error) => Promise.reject(error),
)

export default api
