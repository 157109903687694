import React, { useEffect, useState } from 'react'
import './Login.css'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useAuth } from '../../../provider/AuthProvider'
import { useToast } from '../../../provider/ToastProvider'
import Loading from '../../../shared/loading/Loading'
import { Checkbox } from 'primereact/checkbox'

const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const { login, lembrarMe, lembrarMeUsuario } = useAuth()
    const [lembrarMeCheck, setLembrarMeCheck] = useState(
        lembrarMeUsuario !== null,
    )

    const [loading, setLoading] = useState(false)

    const { showToast } = useToast()

    useEffect(() => {
        if (lembrarMeCheck) {
            setUsername(lembrarMeUsuario.username)
            setPassword(lembrarMeUsuario.password)
        }
    }, [lembrarMeCheck, lembrarMeUsuario, setUsername, setPassword])

    const handleLogin = async () => {
        try {
            setLoading(true)
            lembrarMe({
                lembrarMe: lembrarMeCheck,
                username,
                password,
            })
            await login({
                username,
                password,
            })
        } catch (error) {
            if (
                [400, 401].includes(error.response?.status) &&
                error.response?.data?.mensagens
            ) {
                error.response.data.mensagens.forEach((mensagem) => {
                    showToast({
                        detail: mensagem.descricao,
                        severity: 'error',
                        summary: 'Error',
                    })
                })
                return
            }
            console.log(error)
            showToast({
                detail: 'Falha na autenticação!',
                severity: 'error',
                summary: 'Error',
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className="login-container">
                    <div className="grid col-12 m-0 p-0">
                        <div className="col-offset-1 xl:col-offset-4 p-0"></div>
                        <div className="col-10 xl:col-4 m-0 p-3 login-content">
                            <div className="col-12 m-0 p-0">
                                <div className="grid m-0 p-0">
                                    <div className="col-12 m-0 p-0">
                                        <div className="col-12 m-0 p-0 pb-4 text-center">
                                            <i className="sm:block pi pi-shopping-cart text-3xl"></i>
                                        </div>
                                        <div className="col-12 m-0 p-0 pb-4 text-center">
                                            <span className="text-2xl font-bold">
                                                Calcula Compras
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 m-0 p-0">
                                        <div className="col-12 m-0 p-0 pb-4">
                                            <div className="p-inputgroup">
                                                <InputText
                                                    className="p-inputtext-lg"
                                                    value={username}
                                                    onChange={(e) =>
                                                        setUsername(
                                                            e.target.value,
                                                        )
                                                    }
                                                    placeholder="nome"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 m-0 p-0 pb-4">
                                            <div className="p-inputgroup">
                                                <InputText
                                                    className="p-inputtext-lg"
                                                    value={password}
                                                    onChange={(e) =>
                                                        setPassword(
                                                            e.target.value,
                                                        )
                                                    }
                                                    placeholder="senha"
                                                    type="password"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 m-0 p-0  pb-4">
                                            <div className="flex align-items-center">
                                                <Checkbox
                                                    inputId="lembrar-me"
                                                    onChange={(e) => {
                                                        lembrarMe({
                                                            lembrarMe:
                                                                e.checked,
                                                            username,
                                                            password,
                                                        })
                                                        setLembrarMeCheck(
                                                            e.checked,
                                                        )
                                                    }}
                                                    checked={lembrarMeCheck}
                                                />
                                                <label
                                                    htmlFor="lembrar-me"
                                                    className="ml-2 text-500"
                                                >
                                                    Lembre de mim
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 m-0 p-0">
                                            <Button
                                                style={{ width: '100%' }}
                                                label="Entrar"
                                                onClick={handleLogin}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Login
