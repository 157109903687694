import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../../layout/Layout'
import { Button } from 'primereact/button'
import { DataView } from 'primereact/dataview'
import { Toolbar } from 'primereact/toolbar'
import { useNavigate } from 'react-router-dom'
import { useApp } from '../../../provider/AppProvider'
import { excluir, iniciar, listar } from './CompraService'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import { formatarData, formatarMoeda } from '../../../service/util'
import Loading from '../../../shared/loading/Loading'

const CompraList = () => {
    const [loading, setLoading] = useState(true)

    const [compras, setCompras] = useState([])
    const [pesquisa, setPesquisa] = useState('')
    const navigate = useNavigate()
    const { apiErrorHandle, showToast } = useApp()

    const handleListar = useCallback(async () => {
        console.log('Init CompraList')
        await listar()
            .then((data) => setCompras(data))
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao obter lista de compraes',
                }),
            )
    }, [apiErrorHandle, setCompras])

    useEffect(() => {
        handleListar().finally(() => {
            setLoading(false)
        })
    }, [handleListar, setLoading])

    const handleExcluir = (compraId) => {
        setLoading(true)
        excluir(compraId)
            .then(() => {
                handleListar()
                navigate('/compra')
                showToast({
                    detail: 'Registro excluído com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao excluir o compra',
                }),
            )
            .finally(() => {
                setLoading(false)
            })
    }

    const handleCompraItem = (compra) => {
        console.log(compra)

        if (compra.emCriacao) {
            setLoading(true)
            iniciar(compra)
                .then(() => {
                    navigate(`/compra-item/${compra.id}`)
                })
                .catch((error) =>
                    apiErrorHandle({
                        error,
                        mensagem: 'Falha ao iniciar a compra',
                    }),
                )
                .finally(() => {
                    setLoading(false)
                })
        } else {
            navigate(`/compra-item/${compra.id}`)
        }
    }

    const itemTemplate = (compra) => {
        return (
            <div className="col-12 ">
                <div className="grid m-0 p-0 mb-1 mt-1">
                    <div className="grid col-7 xl:col-10 m-0 p-0 ">
                        <div className="col-12 m-0 p-0">
                            <Tag
                                value={compra.fornecedor.nome}
                                severity="info"
                            />
                        </div>
                        <div className="col-12 text-xs  m-0 p-0 mt-1">
                            {compra.status}
                        </div>
                        <div className="col-12 text-xs  m-0 p-0 mt-1">
                            {formatarData(compra.dataCriacao)}
                        </div>
                    </div>
                    <div className="col-5 xl:col-2 flex flex-wrap gap-2 justify-content-end m-0 p-0">
                        <Button
                            severity="info"
                            icon="pi pi-cart-plus"
                            onClick={() => handleCompraItem(compra)}
                            size="small"
                            text
                        />
                        <Button
                            severity="warning"
                            icon="pi pi-pencil"
                            className={
                                compra.emAndamento || compra.concluida
                                    ? 'hidden'
                                    : ''
                            }
                            onClick={() =>
                                navigate(`/compra/form/${compra.id}`)
                            }
                            size="small"
                            text
                        />
                        <Button
                            severity="danger"
                            icon="pi pi-trash"
                            className={compra.concluida ? 'hidden' : ''}
                            onClick={() => handleExcluir(compra.id)}
                            size="small"
                            text
                        />
                    </div>
                    <div className="grid col-12 m-0 p-0">
                        <div className="col-10 xl:col-11 text-xs m-0 p-0 flex justify-content-end">
                            <span className="font-bold">
                                Qtde. total de itens&nbsp;
                            </span>
                        </div>
                        <div className="col-2 xl:col-1 text-xs m-0 p-0 flex justify-content-end">
                            {(compra.quantidadeTotalItens + '').padStart(
                                3,
                                '0',
                            )}
                        </div>
                        <div className="col-10 xl:col-11 text-xs m-0 p-0 flex justify-content-end">
                            <span className="font-bold">Valor total&nbsp;</span>
                        </div>
                        <div className="col-2 xl:col-1 text-xs m-0 p-0 flex justify-content-end">
                            {formatarMoeda(compra.valorTotalItens)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const startToolbarTemplate = () => {
        return (
            <div>
                <span className="text-xl">Compras</span>
            </div>
        )
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Layout>
                    <div className="grid mt-2 m-0 p-3 xl:p-0">
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="col-12 xl:col-8 m-0 p-0">
                            <Toolbar
                                className="mb-2"
                                start={startToolbarTemplate}
                            ></Toolbar>
                        </div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="grid col-12 xl:col-8 m-0 p-0 mb-2 mt-1">
                            <div className="col-8 xl:col-10 m-0 p-0">
                                <InputText
                                    className="w-full hidden"
                                    value={pesquisa}
                                    onChange={(e) =>
                                        setPesquisa(e.target.value)
                                    }
                                    placeholder="Pesquisar . . . "
                                />
                            </div>
                            <div className="hidden xl:block col-offset-1 p-0"></div>
                            <div className="col-4 xl:col-1 flex flex-wrap gap-2 xl:justify-content-start justify-content-end m-0 p-0 ">
                                <Button
                                    icon="pi pi-search"
                                    className="hidden"
                                    size="small"
                                    text
                                />
                                <Button
                                    icon="pi pi-plus"
                                    severity="success"
                                    onClick={() => navigate('/compra/form')}
                                    size="small"
                                    text
                                />
                            </div>
                        </div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="col-12 xl:col-8 m-0 p-0">
                            <DataView
                                value={compras}
                                itemTemplate={itemTemplate}
                            />
                        </div>
                    </div>
                </Layout>
            )}
        </>
    )
}

export default CompraList
