import api from '../../../service/api'

const endPoint = 'compra'

export async function adicionar({ fornecedor, usuarioId }) {
    try {
        return await api.post(`/${endPoint}`, { fornecedor, usuarioId })
    } catch (error) {
        throw error
    }
}

export async function listar() {
    try {
        const { data } = await api.get(`/${endPoint}`)
        return data
    } catch (error) {
        throw error
    }
}

export async function consultarPorId(id) {
    try {
        const { data } = await api.get(`/${endPoint}/${id}`)
        return data
    } catch (error) {
        throw error
    }
}

export async function atualizar({ id, fornecedor }) {
    try {
        return await api.put(`/${endPoint}`, { id, fornecedor })
    } catch (error) {
        throw error
    }
}

export async function excluir(id) {
    try {
        return await api.delete(`/${endPoint}/${id}`)
    } catch (error) {
        throw error
    }
}

export async function iniciar({ id }) {
    try {
        return await api.put(`/${endPoint}/iniciar/${id}`)
    } catch (error) {
        throw error
    }
}

export async function concluir({ id }) {
    try {
        return await api.put(`/${endPoint}/concluir/${id}`)
    } catch (error) {
        throw error
    }
}
