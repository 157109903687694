import React, { createContext, useContext, useRef } from 'react'
import { Toast } from 'primereact/toast'

const ToastContext = createContext()

export function ToastProvider({ children }) {
    const toast = useRef(null)

    const showToast = ({
        detail,
        severity = 'info',
        summary = 'Info',
        sticky = false,
    }) => {
        toast.current.show({ severity, summary, detail, sticky })
    }

    return (
        <ToastContext.Provider value={{ showToast }}>
            <Toast ref={toast} />
            {children}
        </ToastContext.Provider>
    )
}

export function useToast() {
    return useContext(ToastContext)
}
