import React, { createContext, useContext } from 'react'
import { useToast } from './ToastProvider'
import { useAuth } from './AuthProvider'

const AppContext = createContext()

export function AppProvider({ children }) {
    const { showToast } = useToast()
    const { logout } = useAuth()

    async function apiErrorHandle({ error, mensagem }) {
        if (error.response.status === 401) {
            logout()
            return
        }
        if (
            [400].includes(error.response?.status) &&
            error.response?.data?.mensagens
        ) {
            error.response.data.mensagens.forEach((mensagem) => {
                showToast({
                    detail: mensagem.descricao,
                    severity: 'error',
                    summary: 'Error',
                })
            })
            return
        }
        showToast({ detail: mensagem, severity: 'error', summary: 'Error' })
    }

    return (
        <AppContext.Provider value={{ showToast, apiErrorHandle }}>
            {children}
        </AppContext.Provider>
    )
}

export function useApp() {
    return useContext(AppContext)
}
