import './Layout.css'
import React from 'react'
import { Menubar } from 'primereact/menubar'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../provider/AuthProvider'

function Layout({ children }) {
    const { logout, usuario, isAdmin } = useAuth()
    const navigate = useNavigate()

    const handleLogout = async () => {
        logout()
    }

    const items = [
        {
            label: 'Compras',
            icon: 'pi pi-fw pi-shopping-cart',
            className: 'ml-0 sm:ml-5',
            command: () => navigate('/compra'),
        },
        {
            label: 'Cadastros',
            icon: 'pi pi-fw pi-list',
            className: `${isAdmin ? '' : 'hidden'}`,
            items: [
                {
                    label: 'Produto',
                    icon: 'pi pi-fw pi-bookmark',
                    command: () => navigate('/produto'),
                },
                {
                    label: 'Categoria',
                    icon: 'pi pi-fw pi-bookmark',
                    command: () => navigate('/categoria'),
                },
                {
                    label: 'Fornecedor',
                    icon: 'pi pi-fw pi-bookmark',
                    command: () => navigate('/fornecedor'),
                },
                {
                    label: 'Tipo de Fornecedor',
                    icon: 'pi pi-fw pi-bookmark',
                    command: () => navigate('/tipo-fornecedor'),
                },
            ],
        },
    ]

    const start = (
        <div
            className="ml-3 hidden sm:flex align-items-center"
            onClick={() => navigate('/app')}
        >
            <i className="pi pi-shopping-cart text-3xl"></i>
            <span className="text-xl font-bold ml-2">Calcula Compras</span>
        </div>
    )

    const end = (
        <div className="menu-bar-end">
            <span>{usuario}</span>
            <Button icon="pi pi-fw pi-sign-out" text onClick={handleLogout} />
        </div>
    )

    return (
        <div className="card">
            <Menubar start={start} model={items} end={end} />
            <div className="layout-main">{children}</div>
        </div>
    )
}

export default Layout
