import './Loading.css'

const Loading = () => {
    return (
        <div className="overlay">
            <div className="flex w-12 h-full align-items-center justify-content-center">
                <div className="w-5 flex justify-content-center w-5 border-round backgroundColor255">
                    <div className="inner width200height200">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            className="svgStyle"
                            width="200px"
                            height="200px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                        >
                            <defs>
                                <clipPath
                                    id="ldio-259x9rg9dhm-cp"
                                    x="0"
                                    y="0"
                                    width="100"
                                    height="100"
                                >
                                    <rect
                                        x="0"
                                        y="5"
                                        width="100"
                                        height="46"
                                    ></rect>
                                </clipPath>
                            </defs>
                            <path
                                d="M70 75.2H34.1l-4.1-18.4l-0.7-3l-1-4.7c0 0 0 0 0-0.1c0-0.1 0-0.1-0.1-0.2c0 0 0-0.1-0.1-0.1c0 0 0-0.1-0.1-0.1 c0 0-0.1-0.1-0.1-0.1c0 0-0.1-0.1-0.1-0.1c0 0-0.1-0.1-0.1-0.1c0 0 0 0-0.1-0.1L22.3 44c0-0.1 0-0.2 0-0.3c0-1.9-1.6-3.5-3.5-3.5 s-3.5 1.6-3.5 3.5c0 1.9 1.6 3.5 3.5 3.5c0.7 0 1.4-0.2 2-0.6l4.8 3.7L31.5 77c0 0 0 0 0 0l-5.6 7.7c-0.3 0.5-0.4 1.1-0.1 1.6 c0.3 0.5 0.8 0.8 1.3 0.8h4c-0.8 0.8-1.3 1.9-1.3 3.2c0 2.6 2.1 4.7 4.7 4.7c2.6 0 4.7-2.1 4.7-4.7c0-1.2-0.5-2.3-1.3-3.2h29 c-0.8 0.8-1.3 1.9-1.3 3.2c0 2.6 2.1 4.7 4.7 4.7c2.6 0 4.7-2.1 4.7-4.7c0-1.2-0.5-2.3-1.3-3.2H77c0.8 0 1.5-0.7 1.5-1.5 s-0.7-1.5-1.5-1.5H30l4.3-6h36.8c0.7 0 1.3-0.5 1.4-1.1l7.5-27.3c0.2-0.8-0.2-1.6-1-1.8c-0.8-0.2-1.6 0.2-1.8 1l-1.3 4.7l-0.8 3"
                                fill="#5eb45e"
                            ></path>
                            <polygon
                                points="31.3 53.1 35.7 73.2 68.5 73.2 74 53.1"
                                fill="#5eb45e"
                            ></polygon>
                            <g clip-path="url(#ldio-259x9rg9dhm-cp)">
                                <g>
                                    <g transform="translate(50 41)">
                                        <path
                                            d="M6.5-6.7C6.1-6.9 5.7-7.2 5.3-7.4C5-7.5 4.6-7.7 4.3-7.8C3.1-2.2-4-3.7-2.9-9.3c-0.4 0-0.7 0-1.1 0 c-0.5 0-1 0.1-1.4 0.2c-1.8 0.3-3.6 0.9-5.3 1.8l1.1 4.2l3.1-0.8L-8.7 6.9L3.2 9.3L5.4-1.5l2.5 2l2.7-3.4C9.5-4.4 8.1-5.7 6.5-6.7z"
                                            fill="#165016"
                                        >
                                            <animateTransform
                                                attributeName="transform"
                                                type="rotate"
                                                keyTimes="0;1"
                                                values="0;360"
                                                dur="0.7462686567164178s"
                                                repeatCount="indefinite"
                                            ></animateTransform>
                                        </path>
                                    </g>
                                    <animateTransform
                                        attributeName="transform"
                                        type="translate"
                                        keyTimes="0;1"
                                        values="0 0;0 75"
                                        dur="1.4925373134328357s"
                                        repeatCount="indefinite"
                                    ></animateTransform>
                                </g>
                                <g>
                                    <g transform="translate(35 17)">
                                        <path
                                            d="M3.4-5.3L2.5-5l0.8-2.3L1.1-6.3l-1.2-2.2l-1.6 4.6l-4.6-1.6l0.9 2.3l-2.2 1.2l2.3 0.8L-6-0.9 c-0.6 0.3-0.8 0.9-0.5 1.5l1 2.1C-5.2 3.4-4.6 3.6-4 3.3l0.1-0.1l2.1 4.5C-1.4 8.4-0.7 8.7 0 8.3l1.7-0.8l1.7-0.8L5 5.9l1.7-0.8 C7.4 4.8 7.7 4 7.4 3.3L5.2-1.1l0.1-0.1c0.6-0.3 0.8-0.9 0.5-1.5l-1-2.1C4.6-5.4 3.9-5.6 3.4-5.3z"
                                            fill="#5eb45e"
                                        >
                                            <animateTransform
                                                attributeName="transform"
                                                type="rotate"
                                                keyTimes="0;1"
                                                values="0;360"
                                                dur="0.7462686567164178s"
                                                repeatCount="indefinite"
                                            ></animateTransform>
                                        </path>
                                    </g>
                                    <animateTransform
                                        attributeName="transform"
                                        type="translate"
                                        keyTimes="0;1"
                                        values="0 0;0 75"
                                        dur="1.4925373134328357s"
                                        repeatCount="indefinite"
                                    ></animateTransform>
                                </g>
                                <g>
                                    <g transform="translate(66 26)">
                                        <path
                                            d="M-4.5-3.7L1.9-6l0.5-0.2L2-7.2l-6.9 2.5C-5.7-4.4-6.1-3.5-6-2.7c0 0.1 0 0.2 0.1 0.3l3 8.2 C-2.5 6.9-1.3 7.4-0.2 7l5.6-2C5.9 4.8 6.2 4.2 6 3.7L3.2-3.9l-0.4-1L2.4-4.7L1.9-4.5l-3.2 1.2l-2.7 1c-0.3 0.1-0.6 0-0.8-0.2 c-0.1-0.1-0.1-0.1-0.1-0.2C-5.1-3.1-4.9-3.6-4.5-3.7z"
                                            fill="#8fe68f"
                                        >
                                            <animateTransform
                                                attributeName="transform"
                                                type="rotate"
                                                keyTimes="0;1"
                                                values="0;360"
                                                dur="0.7462686567164178s"
                                                repeatCount="indefinite"
                                            ></animateTransform>
                                        </path>
                                    </g>
                                    <animateTransform
                                        attributeName="transform"
                                        type="translate"
                                        keyTimes="0;1"
                                        values="0 0;0 75"
                                        dur="1.4925373134328357s"
                                        repeatCount="indefinite"
                                    ></animateTransform>
                                </g>
                                <g>
                                    <g transform="translate(55 6)">
                                        <polygon
                                            points="0 -4.9 1.6 -1.7 5.1 -1.1 2.6 1.3 3.2 4.9 0 3.2 -3.2 4.9 -2.6 1.3 -5.1 -1.1 -1.6 -1.7"
                                            fill="#165016"
                                        >
                                            <animateTransform
                                                attributeName="transform"
                                                type="rotate"
                                                keyTimes="0;1"
                                                values="0;360"
                                                dur="0.7462686567164178s"
                                                repeatCount="indefinite"
                                            ></animateTransform>
                                        </polygon>
                                    </g>
                                    <animateTransform
                                        attributeName="transform"
                                        type="translate"
                                        keyTimes="0;1"
                                        values="0 0;0 75"
                                        dur="1.4925373134328357s"
                                        repeatCount="indefinite"
                                    ></animateTransform>
                                </g>
                            </g>
                            <g clip-path="url(#ldio-259x9rg9dhm-cp)">
                                <g transform="translate(0 -75)">
                                    <g>
                                        <g transform="translate(50 41)">
                                            <path
                                                d="M6.5-6.7C6.1-6.9 5.7-7.2 5.3-7.4C5-7.5 4.6-7.7 4.3-7.8C3.1-2.2-4-3.7-2.9-9.3c-0.4 0-0.7 0-1.1 0 c-0.5 0-1 0.1-1.4 0.2c-1.8 0.3-3.6 0.9-5.3 1.8l1.1 4.2l3.1-0.8L-8.7 6.9L3.2 9.3L5.4-1.5l2.5 2l2.7-3.4C9.5-4.4 8.1-5.7 6.5-6.7z"
                                                fill="#165016"
                                            >
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    keyTimes="0;1"
                                                    values="0;360"
                                                    dur="0.7462686567164178s"
                                                    repeatCount="indefinite"
                                                ></animateTransform>
                                            </path>
                                        </g>
                                        <animateTransform
                                            attributeName="transform"
                                            type="translate"
                                            keyTimes="0;1"
                                            values="0 0;0 75"
                                            dur="1.4925373134328357s"
                                            repeatCount="indefinite"
                                        ></animateTransform>
                                    </g>
                                    <g>
                                        <g transform="translate(35 17)">
                                            <path
                                                d="M3.4-5.3L2.5-5l0.8-2.3L1.1-6.3l-1.2-2.2l-1.6 4.6l-4.6-1.6l0.9 2.3l-2.2 1.2l2.3 0.8L-6-0.9 c-0.6 0.3-0.8 0.9-0.5 1.5l1 2.1C-5.2 3.4-4.6 3.6-4 3.3l0.1-0.1l2.1 4.5C-1.4 8.4-0.7 8.7 0 8.3l1.7-0.8l1.7-0.8L5 5.9l1.7-0.8 C7.4 4.8 7.7 4 7.4 3.3L5.2-1.1l0.1-0.1c0.6-0.3 0.8-0.9 0.5-1.5l-1-2.1C4.6-5.4 3.9-5.6 3.4-5.3z"
                                                fill="#5eb45e"
                                            >
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    keyTimes="0;1"
                                                    values="0;360"
                                                    dur="0.7462686567164178s"
                                                    repeatCount="indefinite"
                                                ></animateTransform>
                                            </path>
                                        </g>
                                        <animateTransform
                                            attributeName="transform"
                                            type="translate"
                                            keyTimes="0;1"
                                            values="0 0;0 75"
                                            dur="1.4925373134328357s"
                                            repeatCount="indefinite"
                                        ></animateTransform>
                                    </g>
                                    <g>
                                        <g transform="translate(66 26)">
                                            <path
                                                d="M-4.5-3.7L1.9-6l0.5-0.2L2-7.2l-6.9 2.5C-5.7-4.4-6.1-3.5-6-2.7c0 0.1 0 0.2 0.1 0.3l3 8.2 C-2.5 6.9-1.3 7.4-0.2 7l5.6-2C5.9 4.8 6.2 4.2 6 3.7L3.2-3.9l-0.4-1L2.4-4.7L1.9-4.5l-3.2 1.2l-2.7 1c-0.3 0.1-0.6 0-0.8-0.2 c-0.1-0.1-0.1-0.1-0.1-0.2C-5.1-3.1-4.9-3.6-4.5-3.7z"
                                                fill="#8fe68f"
                                            >
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    keyTimes="0;1"
                                                    values="0;360"
                                                    dur="0.7462686567164178s"
                                                    repeatCount="indefinite"
                                                ></animateTransform>
                                            </path>
                                        </g>
                                        <animateTransform
                                            attributeName="transform"
                                            type="translate"
                                            keyTimes="0;1"
                                            values="0 0;0 75"
                                            dur="1.4925373134328357s"
                                            repeatCount="indefinite"
                                        ></animateTransform>
                                    </g>
                                    <g>
                                        <g transform="translate(55 6)">
                                            <polygon
                                                points="0 -4.9 1.6 -1.7 5.1 -1.1 2.6 1.3 3.2 4.9 0 3.2 -3.2 4.9 -2.6 1.3 -5.1 -1.1 -1.6 -1.7"
                                                fill="#165016"
                                            >
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    keyTimes="0;1"
                                                    values="0;360"
                                                    dur="0.7462686567164178s"
                                                    repeatCount="indefinite"
                                                ></animateTransform>
                                            </polygon>
                                        </g>
                                        <animateTransform
                                            attributeName="transform"
                                            type="translate"
                                            keyTimes="0;1"
                                            values="0 0;0 75"
                                            dur="1.4925373134328357s"
                                            repeatCount="indefinite"
                                        ></animateTransform>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading
