import api from '../../../service/api'

const endPoint = 'compra-item'

export async function adicionar({ produto, compra }) {
    try {
        return await api.post(`/${endPoint}`, { produto, compra })
    } catch (error) {
        throw error
    }
}

export async function listar({ compraId }) {
    try {
        const { data } = await api.get(`/${endPoint}`, {
            params: {
                compraId,
            },
        })
        return data
    } catch (error) {
        throw error
    }
}

export async function consultarPorId(id) {
    try {
        const { data } = await api.get(`/${endPoint}/${id}`)
        return data
    } catch (error) {
        throw error
    }
}

export async function atualizar({ id, quantidade, preco }) {
    try {
        return await api.put(`/${endPoint}`, { id, quantidade, preco })
    } catch (error) {
        throw error
    }
}

export async function excluir(id) {
    try {
        return await api.delete(`/${endPoint}/${id}`)
    } catch (error) {
        throw error
    }
}
