import jwt_decode from 'jwt-decode'
import api from './api'

export async function autenticar({ username, password }) {
    try {
        const { data } = await api.post(`/login`, { username, password })

        var { name, sub, resource_access } = jwt_decode(data.access_token)
        var papelUsuario = resource_access['mpp-calculacompras'].roles

        return {
            token: data,
            usuario: name,
            usuarioId: sub,
            papelUsuario,
        }
    } catch (error) {
        throw error
    }
}
