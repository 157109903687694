import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../../layout/Layout'
import { Toolbar } from 'primereact/toolbar'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from '../../../provider/AppProvider'
import { adicionar, atualizar, consultarPorId } from './FornecedorService'
import { Dropdown } from 'primereact/dropdown'
import { listar as listarTiposFornecedor } from '../tipo-fornecedor/TipoFornecedorService'

const FornecedorForm = () => {
    const navigate = useNavigate()
    const { apiErrorHandle, showToast } = useApp()
    let { fornecedorId } = useParams()
    const [nome, setNome] = useState('')

    const [tiposFornecedor, setTiposFornecedor] = useState([])
    const [tipoFornecedor, setTipoFornecedor] = useState('')

    const handleListar = useCallback(() => {
        console.log('Init TiposFornecedorList')
        listarTiposFornecedor()
            .then((data) => setTiposFornecedor(data))
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao obter lista de tiposFornecedor',
                }),
            )
    }, [apiErrorHandle, setTiposFornecedor])

    useEffect(() => {
        handleListar()
        if (fornecedorId) {
            console.log('Init fornecedorForm')
            consultarPorId(fornecedorId)
                .then((data) => {
                    console.log('data', data)
                    setNome(data.nome)
                    setTipoFornecedor(data.tipoFornecedor)
                })
                .catch((error) =>
                    apiErrorHandle({
                        error,
                        mensagem: 'Falha ao consultar a fornecedor',
                    }),
                )
        }
    }, [apiErrorHandle, fornecedorId, setNome, handleListar])

    const handleSalvar = () => {
        if (fornecedorId) {
            handleAtualizar()
            return
        }
        handleAdicionar()
    }

    const handleAdicionar = () => {
        adicionar({ nome, tipoFornecedor: tipoFornecedor })
            .then(() => {
                navigate('/fornecedor')
                showToast({
                    detail: 'Registro salvo com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao adicionar um novo fornecedor',
                }),
            )
    }

    const handleAtualizar = () => {
        atualizar({ id: fornecedorId, nome, tipoFornecedor: tipoFornecedor })
            .then(() => {
                navigate('/fornecedor')
                showToast({
                    detail: 'Registro atualizado com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao adicionar um novo fornecedor',
                }),
            )
    }

    const startToolbarTemplate = () => {
        return (
            <div>
                <span className="text-xl">Fornecedor</span>
            </div>
        )
    }

    return (
        <Layout>
            <div className="grid mt-2 m-0 p-3 xl:p-0">
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0">
                    <Toolbar className="mb-2" start={startToolbarTemplate} />
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0 ">
                    <Dropdown
                        value={tipoFornecedor}
                        onChange={(e) => setTipoFornecedor(e.value)}
                        options={tiposFornecedor}
                        optionLabel="nome"
                        placeholder="Selecione o tipo"
                        className="p-inputtext-lg w-full mb-2"
                    />
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0 ">
                    <InputText
                        type="text"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        className="p-inputtext-lg w-full mb-2"
                        placeholder="Informe o nome do fornecedor"
                    />
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-8 p-0"></div>
                <div className="col-12 xl:col-2 m-0 p-0 flex justify-content-end ">
                    <Button
                        label="Salvar"
                        icon="pi pi-check"
                        onClick={handleSalvar}
                        className="mr-2"
                        size="small"
                        text
                    />
                    <Button
                        label="Cancelar"
                        onClick={() => navigate('/fornecedor')}
                        severity="danger"
                        icon="pi pi-times"
                        size="small"
                        text
                    />
                </div>
            </div>
        </Layout>
    )
}

export default FornecedorForm
