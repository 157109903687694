import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../../layout/Layout'
import { Toolbar } from 'primereact/toolbar'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from '../../../provider/AppProvider'
import { adicionar, atualizar, consultarPorId } from './ProdutoService'
import { Dropdown } from 'primereact/dropdown'
import { listar as listarCategorias } from '../categoria/CategoriaService'

const ProdutoForm = () => {
    const navigate = useNavigate()
    const { apiErrorHandle, showToast } = useApp()
    let { produtoId } = useParams()
    const [nome, setNome] = useState('')

    const [categorias, setCategorias] = useState([])
    const [categoria, setCategoria] = useState('')

    const handleListar = useCallback(() => {
        console.log('Init CategoriaList')
        listarCategorias()
            .then((data) => setCategorias(data))
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao obter lista de categorias',
                }),
            )
    }, [apiErrorHandle, setCategorias])

    useEffect(() => {
        handleListar()
        if (produtoId) {
            console.log('Init produtoForm')
            consultarPorId(produtoId)
                .then((data) => {
                    console.log('data', data)
                    setNome(data.nome)
                    setCategoria(data.categoria)
                })
                .catch((error) =>
                    apiErrorHandle({
                        error,
                        mensagem: 'Falha ao consultar a produto',
                    }),
                )
        }
    }, [apiErrorHandle, produtoId, setNome, handleListar])

    const handleSalvar = () => {
        if (produtoId) {
            handleAtualizar()
            return
        }
        handleAdicionar()
    }

    const handleAdicionar = () => {
        adicionar({ nome, categoria })
            .then(() => {
                navigate('/produto')
                showToast({
                    detail: 'Registro salvo com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao adicionar um novo produto',
                }),
            )
    }

    const handleAtualizar = () => {
        atualizar({ id: produtoId, nome, categoria })
            .then(() => {
                navigate('/produto')
                showToast({
                    detail: 'Registro atualizado com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao adicionar um novo produto',
                }),
            )
    }

    const startToolbarTemplate = () => {
        return (
            <div>
                <span className="text-xl">Produto</span>
            </div>
        )
    }

    return (
        <Layout>
            <div className="grid mt-2 m-0 p-3 xl:p-0">
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0">
                    <Toolbar className="mb-2" start={startToolbarTemplate} />
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0 ">
                    <Dropdown
                        value={categoria}
                        onChange={(e) => setCategoria(e.value)}
                        options={categorias}
                        optionLabel="nome"
                        placeholder="Selecione a categoria"
                        className="p-inputtext-lg w-full mb-2"
                    />
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0 ">
                    <InputText
                        type="text"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        className="p-inputtext-lg w-full mb-2"
                        placeholder="Informe o nome da produto"
                    />
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-8 p-0"></div>
                <div className="col-12 xl:col-2 m-0 p-0 flex justify-content-end ">
                    <Button
                        label="Salvar"
                        icon="pi pi-check"
                        onClick={handleSalvar}
                        className="mr-2"
                        size="small"
                        text
                    />
                    <Button
                        label="Cancelar"
                        onClick={() => navigate('/produto')}
                        severity="danger"
                        icon="pi pi-times"
                        size="small"
                        text
                    />
                </div>
            </div>
        </Layout>
    )
}

export default ProdutoForm
