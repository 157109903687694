import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../../layout/Layout'
import { Button } from 'primereact/button'
import { DataView } from 'primereact/dataview'
import { Toolbar } from 'primereact/toolbar'
import { useNavigate } from 'react-router-dom'
import { useApp } from '../../../provider/AppProvider'
import { excluir, listar } from './CategoriaService'
import { InputText } from 'primereact/inputtext'

const CategoriaList = () => {
    const [categorias, setCategorias] = useState([])
    const [pesquisa, setPesquisa] = useState('')
    const navigate = useNavigate()
    const { apiErrorHandle, showToast } = useApp()

    const handleListar = useCallback(() => {
        console.log('Init CategoriaList')
        listar()
            .then((data) => setCategorias(data))
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao obter lista de categorias',
                }),
            )
    }, [apiErrorHandle, setCategorias])

    useEffect(() => {
        handleListar()
    }, [handleListar])

    const handleExcluir = (categoriaId) => {
        excluir(categoriaId)
            .then(() => {
                handleListar()
                navigate('/categoria')
                showToast({
                    detail: 'Registro excluído com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao excluir a categoria',
                }),
            )
    }

    const itemTemplate = (categoria) => {
        return (
            <div className="col-12 ">
                <div className="grid m-0 p-0 mb-1 mt-1">
                    <div className="col-8 xl:col-11 m-0 p-0">
                        {categoria.nome}
                    </div>
                    <div className="col-4 xl:col-1 flex flex-wrap gap-2 xl:justify-content-start justify-content-end m-0 p-0 ">
                        <Button
                            severity="warning"
                            icon="pi pi-pencil"
                            onClick={() =>
                                navigate(`/categoria/form/${categoria.id}`)
                            }
                            size="small"
                            text
                        />
                        <Button
                            severity="danger"
                            icon="pi pi-times"
                            onClick={() => handleExcluir(categoria.id)}
                            size="small"
                            text
                        />
                    </div>
                </div>
            </div>
        )
    }

    const startToolbarTemplate = () => {
        return (
            <div>
                <span className="text-xl">Categoria</span>
            </div>
        )
    }

    return (
        <Layout>
            <div className="grid mt-2 m-0 p-3 xl:p-0">
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0">
                    <Toolbar
                        className="mb-2"
                        start={startToolbarTemplate}
                    ></Toolbar>
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="grid col-12 xl:col-8 m-0 p-0 mb-2 mt-1">
                    <div className="col-8 xl:col-10 m-0 p-0">
                        <InputText
                            className="w-full hidden"
                            value={pesquisa}
                            onChange={(e) => setPesquisa(e.target.value)}
                            placeholder="Pesquisar . . . "
                        />
                    </div>
                    <div className="hidden xl:block col-offset-1 p-0"></div>
                    <div className="col-4 xl:col-1 flex flex-wrap gap-2 xl:justify-content-start justify-content-end m-0 p-0 ">
                        <Button
                            icon="pi pi-search"
                            className="hidden"
                            size="small"
                            text
                        />
                        <Button
                            icon="pi pi-plus"
                            severity="success"
                            onClick={() => navigate('/categoria/form')}
                            size="small"
                            text
                        />
                    </div>
                </div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="hidden xl:block col-offset-2 p-0"></div>
                <div className="col-12 xl:col-8 m-0 p-0">
                    <DataView value={categorias} itemTemplate={itemTemplate} />
                </div>
            </div>
        </Layout>
    )
}

export default CategoriaList
