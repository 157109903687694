import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../../layout/Layout'
import { Button } from 'primereact/button'
import { DataView } from 'primereact/dataview'
import { Toolbar } from 'primereact/toolbar'
import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from '../../../provider/AppProvider'
import { excluir, listar } from './CompraItemService'
import { InputText } from 'primereact/inputtext'
import {
    concluir,
    consultarPorId as consultarCompraPorId,
} from '../compra/CompraService'
import { Tag } from 'primereact/tag'
import { formatarMoeda } from '../../../service/util'
import Loading from '../../../shared/loading/Loading'

const CompraItemList = () => {
    const [loading, setLoading] = useState(true)

    let { compraId } = useParams()
    const [compra, setCompra] = useState({
        fornecedor: '',
        valorTotalItens: 0,
        quantidadeTotalItens: 0,
    })

    const [compraItens, setCompraItens] = useState([])
    const [compraItensFilter, setCompraItensFilter] = useState()

    const [pesquisa, setPesquisa] = useState('')
    const navigate = useNavigate()
    const { apiErrorHandle, showToast } = useApp()

    const handleListar = useCallback(async () => {
        console.log('Init CompraItemList')
        await listar({ compraId: compraId })
            .then((data) => {
                setCompraItens(data)
                setCompraItensFilter(data)
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao obter lista de compra-itens',
                }),
            )
    }, [apiErrorHandle, setCompraItens, compraId])

    useEffect(() => {
        const init = async () => {
            await consultarCompraPorId(compraId)
                .then((data) => {
                    setCompra(data)
                })
                .catch((error) =>
                    apiErrorHandle({
                        error,
                        mensagem: 'Falha ao consultar a compra',
                    }),
                )
            await handleListar()
        }

        init().finally(() => {
            setLoading(false)
        })
    }, [handleListar, compraId, apiErrorHandle])

    const handleExcluir = (compraItemId) => {
        setLoading(true)
        excluir(compraItemId)
            .then(() => {
                handleListar()
                navigate(`/compra-item/${compraId}`)
                showToast({
                    detail: 'Registro excluído com sucesso!',
                    severity: 'success',
                    summary: 'Success',
                })
            })
            .catch((error) =>
                apiErrorHandle({
                    error,
                    mensagem: 'Falha ao excluir o compra-item',
                }),
            )
            .finally(() => {
                setLoading(false)
            })
    }

    const normalize = (valor) => {
        return valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }
    const onFilterChange = (e) => {
        const value = e.target.value
        setPesquisa(value)
        setCompraItensFilter(
            compraItens.filter((compraItem) =>
                normalize(compraItem.produto.nome.toLowerCase()).includes(
                    normalize(value.toLowerCase()),
                ),
            ),
        )
    }

    const limparPesquisa = () => {
        setPesquisa('')
        setCompraItensFilter(compraItens)
    }

    const handleConcluirCompra = (compra) => {
        if (compra.emAndamento) {
            setLoading(true)
            concluir(compra)
                .then(() => {
                    navigate(`/compra-item/${compraId}`)
                    showToast({
                        detail: 'Compra concluída com sucesso!',
                        severity: 'success',
                        summary: 'Success',
                    })
                })
                .catch((error) =>
                    apiErrorHandle({
                        error,
                        mensagem: 'Falha ao concluir a compra',
                    }),
                )
                .finally(() => {
                    setLoading(false)
                })
        } else {
            navigate(`/compra-item/${compraId}`)
        }
    }

    const itemTemplate = (compraItem) => {
        return (
            <div
                className={
                    'col-12 mt-1 pl-2 pr-2 ' +
                    (compraItem.preco === 0 ? 'bg-red-100' : 'bg-green-100')
                }
            >
                <div className="grid m-0 p-0 mb-1 mt-1">
                    <div className="grid col-7 xl:col-11 m-0 p-0">
                        <div className="col-12 m-0 p-0">
                            <Tag
                                value={compraItem.produto.nome}
                                severity="info"
                            />
                        </div>
                        <div className="col-12 text-xs  m-0 p-0 mt-1">
                            {compraItem.produto.categoria.nome}
                        </div>
                    </div>
                    <div className="col-5 xl:col-1 flex flex-wrap gap-2 xl:justify-content-start justify-content-end m-0 p-0 ">
                        <Button
                            severity="warning"
                            icon="pi pi-pencil"
                            className={compra.concluida ? 'hidden' : ''}
                            onClick={() =>
                                navigate(
                                    `/compra-item/form/${compraId}/${compraItem.id}`,
                                )
                            }
                            size="small"
                            text
                        />
                        <Button
                            severity="danger"
                            icon="pi pi-trash"
                            className={compra.concluida ? 'hidden' : ''}
                            onClick={() => handleExcluir(compraItem.id)}
                            size="small"
                            text
                        />
                    </div>
                    <div className="grid col-12 m-0 p-0">
                        <div className="col-10 xl:col-11 text-xs m-0 p-0 mt-2 flex justify-content-end">
                            <span className="font-bold">Quantidade:&nbsp;</span>
                        </div>
                        <div className="col-2 xl:col-1 text-xs m-0 p-0 mt-2 flex justify-content-end">
                            {(compraItem.quantidade + '').padStart(3, '0')}
                        </div>
                        <div className="col-10 xl:col-11 text-xs m-0 p-0 flex justify-content-end">
                            <span className="font-bold">Preço:&nbsp;</span>
                        </div>
                        <div className="col-2 xl:col-1 text-xs m-0 p-0 flex justify-content-end">
                            {formatarMoeda(compraItem.preco)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const startToolbarTemplate = () => {
        return (
            <div>
                <span className="text-xl">{compra.fornecedor.nome}</span>
            </div>
        )
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Layout>
                    <div className="grid mt-2 m-0 p-3 xl:p-0">
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="col-12 xl:col-8 m-0 p-0">
                            <Toolbar
                                className="mb-2 min-height66px"
                                start={startToolbarTemplate}
                            ></Toolbar>
                        </div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="grid col-12 xl:col-8 m-0 p-0 mb-2 mt-1">
                            <div className="col-7 xl:col-5 m-0 p-0">
                                <InputText
                                    className="w-full"
                                    value={pesquisa}
                                    onChange={onFilterChange}
                                    placeholder="Pesquisar . . . "
                                />
                            </div>
                            <div
                                className={
                                    'col-3 xl:col-1 flex flex-wrap gap-2 m-0 p-0 xl:ml-2' +
                                    (compra.concluida
                                        ? ''
                                        : ' justify-content-end')
                                }
                            >
                                <Button
                                    icon="pi pi-times"
                                    severity="danger"
                                    className={compra.concluida ? 'ml-2' : ''}
                                    disabled={pesquisa.length === 0}
                                    onClick={limparPesquisa}
                                    size="small"
                                    text
                                />
                                <Button
                                    icon="pi pi-plus"
                                    severity="success"
                                    className={compra.concluida ? 'hidden' : ''}
                                    onClick={() =>
                                        navigate(
                                            `/compra-item/form/${compraId}`,
                                        )
                                    }
                                    size="small"
                                    text
                                />
                            </div>
                        </div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="col-12 xl:col-8 m-0 p-0">
                            <DataView
                                value={compraItensFilter}
                                itemTemplate={itemTemplate}
                            />
                        </div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="hidden xl:block col-offset-2 p-0"></div>
                        <div className="grid col-2 xl:col-2 m-0 p-0 mt-4">
                            <div className="col-12 m-0 p-0">
                                <Button
                                    severity="warning"
                                    icon="pi pi-check-square"
                                    className={
                                        compra.emAndamento ? '' : 'hidden'
                                    }
                                    label="Concluir"
                                    onClick={() => handleConcluirCompra(compra)}
                                    size="small"
                                    text
                                />
                            </div>
                        </div>
                        <div className="grid col-10 xl:col-6 m-0 p-0 mt-4">
                            <div className="col-10 xl:col-11 text-xs m-0 p-0 flex justify-content-end">
                                <span className="font-bold">
                                    Qtde. total de itens&nbsp;
                                </span>
                            </div>
                            <div className="col-2 xl:col-1 text-xs m-0 p-0 flex justify-content-end">
                                {(compra.quantidadeTotalItens + '').padStart(
                                    3,
                                    '0',
                                )}
                            </div>
                            <div className="col-10 xl:col-11 text-xs m-0 p-0 flex justify-content-end">
                                <span className="font-bold">
                                    Valor total:&nbsp;
                                </span>
                            </div>
                            <div className="col-2 xl:col-1 text-xs m-0 p-0 flex justify-content-end">
                                {formatarMoeda(compra.valorTotalItens)}
                            </div>
                        </div>
                    </div>
                </Layout>
            )}
        </>
    )
}

export default CompraItemList
