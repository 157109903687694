import './NotFoundPage.css'
import React from 'react'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'

function NotFoundPage() {
    const navigate = useNavigate()
    return (
        <div className="not-found-page">
            <Card
                className="card-notfound"
                title="Erro 404"
                subTitle="Página não encontrada"
            >
                <p>A página que você está procurando não foi encontrada.</p>
                <Button
                    label="Voltar para a página inicial"
                    onClick={() => navigate('/app')}
                />
            </Card>
        </div>
    )
}

export default NotFoundPage
