import moment from 'moment-timezone'

export const formatarMoeda = (moeda) => {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    })
    return formatter.format(moeda)
}

export const formatarData = (dataString) => {
    const data = moment(dataString)
    data.tz('UTC')

    return data.format('YYYY-MM-DD HH:mm:ss')
}
