import api from '../../../service/api'

const endPoint = 'categoria'

export async function adicionar({ nome }) {
    try {
        return await api.post(`/${endPoint}`, { nome })
    } catch (error) {
        throw error
    }
}

export async function listar() {
    try {
        const { data } = await api.get(`/${endPoint}`)
        return data
    } catch (error) {
        throw error
    }
}

export async function consultarPorId(id) {
    try {
        const { data } = await api.get(`/${endPoint}/${id}`)
        return data
    } catch (error) {
        throw error
    }
}

export async function atualizar({ nome, id }) {
    try {
        return await api.put(`/${endPoint}`, { id, nome })
    } catch (error) {
        throw error
    }
}

export async function excluir(id) {
    try {
        return await api.delete(`/${endPoint}/${id}`)
    } catch (error) {
        throw error
    }
}
