import './App.css'
import { PrimeReactProvider } from 'primereact/api'
import AppRoutes from './routes/AppRoutes'

const packageJson = require('../package.json')

function App() {
    const appVersion = packageJson.version
    document.title = `Calcula Compras - v.${appVersion}`

    return (
        <PrimeReactProvider>
            <AppRoutes />
        </PrimeReactProvider>
    )
}

export default App
