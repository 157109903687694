import { Navigate } from 'react-router-dom'
import { useAuth } from '../provider/AuthProvider'

const ProtectedRoute = ({ children }) => {
    const { logged } = useAuth()
    if (!logged) {
        return <Navigate to="/" replace />
    }

    return children
}

export default ProtectedRoute
